.v-payments-report-form {
  .v-date-inputs {
    flex: 0 1 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;

    #startDate {
      max-width: 12rem;
      margin: 3px;
    }

    #endDate {
      max-width: 12rem;
      margin: 3px;
    }
  }

  #reportTypeSelect {
    max-width: 35rem;
  }

  #organizationSelect {
    max-width: 35rem;
  }
}

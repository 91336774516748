@media print {
  .v-day-header {
    color: black !important;
    font-weight: normal !important;
    font-size: 1.4rem;

    .v-day-number {
      display: none;
    }
  }
}

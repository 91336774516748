@import './commonStyles';

.v-resource-calendar-container {
  box-shadow: $container-shadow;
  background: #ffffff;
}

.v-reserve-btn {
  /* position will be overridden by javascript, these are just some initial values */
  position: absolute;
  bottom: -35px;
  right: 12px;
  filter: drop-shadow(0 2px 3px #666);
}

@media print {
  .v-reserve-btn {
    display: none !important;
  }
}

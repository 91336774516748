@import '../commonStyles';

.v-resource-description-container {
  padding-left: 5px;
  padding-right: 5px;

  .v-desc-content {
    padding: 1rem;
    border: 1px solid rgb(161, 161, 161);
    border-radius: 3px;
    height: 5rem;
    overflow: hidden;
    position: relative;
  }
  .v-desc-content:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.7) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .v-open-button-container {
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;

    a {
      box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.9);
      background: rgba(255, 255, 255, 0.9);
    }
  }
}

@media screen and (min-width: $min-width-sm) {
  .v-resource-description-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media print {
  .v-desc-content {
    display: none;
  }
  .v-open-button-container {
    display: none;
  }
}

@import './../../commonStyles';

$stripes1: #e7e7e7;
$stripes2: #d8d8d8;
$stripe-width: 0.265rem;
.v-range-disabled {
  background: repeating-linear-gradient(
    45deg,
    $stripes1,
    $stripes1 $stripe-width,
    $stripes2 $stripe-width,
    $stripes2 2 * $stripe-width
  );
}

.v-range-disabled img {
  display: none;
}

@media print {
  .v-range-disabled img {
    display: block;
  }
}

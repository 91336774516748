@import '../commonStyles';

@media print {
  .navbar-collapse {
    visibility: hidden;
  }
}

.v-brand-img {
  height: 20px;
  max-width: 100px;
}

.v-org-name {
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 230px;
  font-size: 1rem !important;
}

.v-dropdown-email {
  display: inline-block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (min-width: $min-width-sm) {
  .v-org-name {
    max-width: 230px;
  }

  .v-dropdown-email {
    max-width: 120px;
  }
}

@media screen and (min-width: $min-width-md) {
  .v-org-name {
    max-width: 350px;
  }

  .v-dropdown-email {
    max-width: 160px;
  }
}

@media screen and (min-width: $min-width-lg) {
  .v-org-name {
    max-width: 400px;
  }

  .v-dropdown-email {
    max-width: 170px;
  }

  .v-brand-img {
    max-width: 200px;
  }
}

@media screen and (min-width: $min-width-xl) {
  .v-org-name {
    max-width: 500px;
  }

  .v-dropdown-email {
    max-width: 290px;
  }
}

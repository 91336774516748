@import './../../../commonStyles';

.v-reservation-form-time-container {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  #startDate {
    margin: 3px;
    flex: 0 1 100%;
    max-width: 12rem;
  }

  #repeatLastDate {
    margin: 3px;
    flex: 0 1 100%;
    max-width: 12rem;
  }

  .v-time-inputs {
    flex: 0 1 100%;
    margin-top: 10px;
    display: flex;
    align-items: center;

    #startTime {
      max-width: 8rem;
      margin: 3px;
    }

    #endTime {
      max-width: 8rem;
      margin: 3px;
    }
  }
}

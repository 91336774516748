@import '../commonStyles';

.v-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #aeabb3;
  text-align: center;
  background-color: rgb(77, 78, 79);

  .v-footer-container {
    display: flex;
    justify-content: flex-end;

    #v-fluxio-powered {
      flex: 0 1 100%;
      text-align: right;
      img {
        height: 50px;
      }
    }

    #v-links {
      font-size: 0.8rem;
    }
  }
  #v-version-tag {
    margin-top: 10px;
    font-size: 0.6rem;
  }
}

@media print {
  .v-footer {
    display: none;
  }
}

@import './../../commonStyles';

.StripeElement {
  display: block;
  margin: 8px 0 0 0;
  padding: 10px 14px;
  border-radius: 0.25rem;
  border: 1px solid $gray-400;
  background: white;
  transition: all 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0 0 0.2rem rgba($gray-400, 0.25);
}

.StripeElement--invalid {
  border-color: map-get($theme-colors, danger);
}

.StripeElement--focus.StripeElement--invalid {
  box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, danger), 0.25);
}

.StripeElement--complete {
  border-color: map-get($theme-colors, success);
}

.StripeElement--focus.StripeElement--complete {
  box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, success), 0.25);
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}

.v-load-money-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.v-load-money-form {
  .v-card-logo {
    height: 2rem;
  }
  .v-stripe-logo {
    height: 1.5rem;
  }
}

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$theme-colors: (
  primary: $green,
  secondary: $gray-600,
  success: $blue,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);

$min-width-sm: 576px;
$min-width-md: 768px;
$min-width-lg: 992px;
$min-width-xl: 1200px;

$calendar-cell-main-border-color: #d4d4d4;
$calendar-cell-middle-border-color: #e0e0e0;
$calendar-cell-today-bg: #f5f5f5;
$calendar-cell-reservable-hover-bg: darken($calendar-cell-today-bg, 3%);

$container-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

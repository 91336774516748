@import '../../commonStyles';

.v-calendar-controls {
  padding: 5px;
}

.v-control-btn-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.v-control-browse-btns-grp {
  order: 1;
  flex: 0 1 165px;
}

.v-control-drop-grp {
  order: 2;
}

.v-control-edit-grp {
  order: 3;
  flex: 0 1 165px;
}

@media screen and (min-width: $min-width-sm) {
  .v-calendar-controls {
    padding: 10px;
  }
}

@media screen and (max-width: $min-width-md) {
  .v-control-drop {
    width: 100%;
  }
  .v-control-drop-grp {
    width: 100%;
    order: 3;
    margin-top: 10px;
  }
  .v-control-edit-grp {
    order: 2;
  }
}

@media print {
  .v-calendar-controls {
    display: none;
  }
}

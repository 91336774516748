@import '../commonStyles';

.v-resource-selection-container {
  padding: 5px;
  padding-bottom: 10px;
}

@media screen and (min-width: $min-width-sm) {
  .v-resource-selection-container {
    padding: 10px;
  }
}

@media print {
  .v-resource-selection-container {
    display: none;
  }
}

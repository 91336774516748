@import './../../../commonStyles';

.v-reservation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

#reservation-hp {
  display: none;
}

@import './commonStyles';

html {
  font-size: 14px;
}

@media screen and (min-width: $min-width-md) {
  html {
    font-size: 16px;
  }
}

.v-calendar-container {
  filter: drop-shadow(0 0 20px #f2f2f2);
}

div.v-container {
  padding-top: 50px;
}

@media screen and (max-width: $min-width-sm) {
  div.v-container {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

@import './../commonStyles';

.v-content-container {
  padding: 20px 10px;
  box-shadow: $container-shadow;
  background: #ffffff;
}

@media screen and (min-width: $min-width-md) {
  .v-content-container {
    padding: 40px 30px;
  }
}

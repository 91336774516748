@import '../../commonStyles';

$reservation-color: darken(#d3effa, 5%);
$reservation-color-primary: darken(#d5fbab, 5%);

.v-reservation {
  width: 90%;
  overflow: hidden;
  font-size: 0.7rem;
  padding: 1px;
  border-left-style: solid;
  border-left-width: 3px;
  border-color: darken($reservation-color, 20%);
  background: $reservation-color;
}

.v-reservation:not(.no-hover):hover {
  border-color: darken($reservation-color, 25%);
  background: darken($reservation-color, 5%);
  cursor: pointer;
}

.v-reservation.v-reservation-primary {
  border-left-color: darken($reservation-color-primary, 35%);
  background: $reservation-color-primary;
}

.v-reservation.v-reservation-primary:hover {
  border-left-color: darken($reservation-color-primary, 40%);
  background: darken($reservation-color-primary, 5%);
}

.v-reservation.v-reservation-past {
  color: #888;
  border-color: darken($reservation-color, 15%);
  background: lighten($reservation-color, 5%);
}

.v-reservation.v-reservation-past:not(.no-hover):hover {
  border-color: darken($reservation-color, 20%);
  background: lighten($reservation-color, 10%);
}

.v-reservation.v-reservation-pre {
  color: darken($reservation-color-primary, 50%);
  border-style: dashed;
  border-width: 3px;
  border-color: darken($reservation-color-primary, 40%);
  background: $reservation-color-primary;
  opacity: 0.65;
  cursor: pointer;
}
.v-reservation.v-reservation-pre:hover {
  color: darken($reservation-color-primary, 50%);
  border-color: darken($reservation-color-primary, 40%);
  background: $reservation-color-primary;
  opacity: 0.9;
}

.v-reservation.v-reservation-past.v-reservation-primary {
  border-color: darken($reservation-color-primary, 30%);
  background: lighten($reservation-color-primary, 5%);
}

.v-reservation.v-reservation-past.v-reservation-primary:hover {
  border-color: darken($reservation-color-primary, 35%);
  background: lighten($reservation-color-primary, 0%);
}

.v-reservation-unconfirmed {
  opacity: 0.6;
}

@media screen and (min-width: $min-width-sm) {
  .v-reservation {
    border-left-width: 5px;
  }

  .v-reservation.v-reservation-pre {
    border-width: 4px;
  }
}

.v-desc-hidden {
  display: none;
}

.v-desc-visible-hidden {
  opacity: 0.5;
}

@media print {
  .v-reservation {
    border-style: solid;
    border-width: 1px;
    border-left-width: 5px;
    border-color: darken($reservation-color, 40%) !important;
  }
}

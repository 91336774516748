@import './../../commonStyles';

.v-hour-top-cell {
  border-top: 1px solid $calendar-cell-main-border-color;
  border-left: 1px solid $calendar-cell-main-border-color;
}

.v-hour-bottom-cell {
  border-top: 1px dotted $calendar-cell-middle-border-color;
  border-left: 1px solid $calendar-cell-main-border-color;
}

.v-hour-reservable {
  cursor: pointer;

  &:hover {
    background: $calendar-cell-reservable-hover-bg;
  }
}

@keyframes sk-fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.v-full-spinner-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow: visible;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: sk-fade-in 1s; // Half of this time is the delay before wait indicator is shown

  .v-spinner {
    .sk-double-bounce {
      height: 10vh;
      width: 10vh;
    }
  }
}

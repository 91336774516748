@import './../../commonStyles';

.input-max-w-lg {
  max-width: 600px;
}

.input-max-w-md {
  max-width: 400px;
}

.input-max-w-sm {
  max-width: 200px;
}
